import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import CatalogPage from "./pages/Catalog";
import ContactPage from "./pages/contact";
import MarketingPage from "./pages/Marketing";
import VideosPage from "./pages/videos";
import TermsPrivacyPage from "./pages/TermsPrivacy";

import MainNav from "./components/MainNav";
import Footer from "./components/Footer";
import { gql, useQuery } from "@apollo/client";
import Spinner from "components/shared/Spinner";
import { AppContext } from "App";
import Products from "pages/products/List";
import Logos from "pages/Logos";
import LifestyleImages from "pages/LifestyleImages";
import Catalog from "./pages/Catalog";
import ShipmentsPage from "./pages/shipments/index";
import OrderPage from "pages/orders";
import Cart from "pages/cart";
import ProductDetailPage from "pages/products/ProductDetailPage";
import LoginPages from "pages/auth";
import RegisterPage from "pages/auth/RegisterPage";
import Landing2025 from "pages/Landing2025";

const FETCH_ME = gql`
   query FETCH_ME {
      me {
         id
         email
         firstName
         lastName
         isSuperuser
         isStaff
         groups {
            id
            name
         }
      }
   }
`;

function AppRoutes() {
   const { loading, data } = useQuery(FETCH_ME);
   const { setUser } = useContext(AppContext);
   const [inited, setInited] = useState(false);

   useEffect(() => {
      if (data) setUser(data.me);
   }, [data]);

   useEffect(() => {
      if (!loading) setInited(true);
   }, [loading]);

   if (loading || !inited) return <Spinner />;

   return (
      <Routes>
         <Route path="/login/*" element={<LoginPages />} />
         <Route path="/register" element={<RegisterPage />} />

         <Route element={<Layout />}>
            <Route path="/public/catalog" element={<Catalog />} />
            <Route path="/terms-privacy" element={<TermsPrivacyPage />} />
         </Route>
         <Route
            path="*"
            element={
               <Auth>
                  <ProectedPages />
               </Auth>
            }
         />
      </Routes>
   );
}

const Auth = ({ children }) => {
   const { user } = useContext(AppContext);
   const location = useLocation();

   if (!user)
      return <Navigate to="/login" state={{ from: location }} replace />;

   return children;
};

const ProectedPages = () => {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="/landing" element={<Landing2025 />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:slug" element={<ProductDetailPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/lifestyle-images" element={<LifestyleImages />} />
            <Route path="/logos" element={<Logos />} />
            <Route path="/videos" element={<VideosPage />} />
            <Route path="/sales-and-marketing" element={<MarketingPage />} />
            <Route path="/orders/*" element={<OrderPage />} />
            <Route path="/shipments/*" element={<ShipmentsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<Navigate to="/landing" />} />
         </Route>
      </Routes>
   );
};

const Layout = () => {
   return (
      <div className="relative z-20 text-sm flex flex-1 flex-col min-h-screen bg-gray-50">
         <div className="top-0 z-50">
            <MainNav />
         </div>
         <div className="flex-1 relative flex flex-col">
            <Outlet />
         </div>
         <Footer />
      </div>
   );
};

export default AppRoutes;
