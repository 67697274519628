import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import LoginWithCodeView from "./LoginWithCodeView";
import BizLogo from "components/BizLogo";
import { Button } from "components/shared/base";
import useRandomImage from "hooks/useRandomImage";
import { useContext } from "react";
import { AppContext } from "App";

const LoginPages = () => {
  const { user } = useContext(AppContext);
  if (user) return <Navigate to="/" replace />;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<LoginWithCodeView />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  const randomPromoImageQuery = useRandomImage();

  return (
    <div
      className="bg-center bg-cover h-screen"
      style={
        randomPromoImageQuery.data &&
        randomPromoImageQuery.data.randomPromoImage &&
        randomPromoImageQuery.data.randomPromoImage.url.includes(".waboba.com")
          ? {
              backgroundImage: `url(${
                randomPromoImageQuery.data.randomPromoImage.url
              })`
            }
          : {
              backgroundImage: `url(https://cdn.waboba.com/assets/promo_images/Waboba_Moonshine-36_kmNfXkc.jpg)` // Default image
            }
      }
    >
      <div className="container px-16 h-screen flex items-center">
        <div className="bg-white bg-opacity-80 backdrop-blur-xl rounded-3xl p-8 py-14 max-w-md w-full">
          <BizLogo />
          <div className="text-center my-4 leading-normal">
            <h3>Put a bounce in your sales!</h3>
            <p className="mt-2 text-xs">
              Login for Waboba Sales & Marketing Materials.
            </p>
          </div>
          <Outlet />
        </div>
      </div>
      <div className="sticky bottom-0 left-0 container text-xs z-10">
        <div
          className="text-white p-16 leading-normal"
          style={{ textShadown: "0 0 15px #00000075" }}
        >
          <p>
            To learn more about Waboba, please visit{" "}
            <a
              className="text-white font-bold hover:underline text-shadow"
              target="_blank"
              rel="noopener noreferrer"
              href="https://waboba.com"
            >
              waboba.com
            </a>
          </p>
          <p>
            Copyright © {new Date().getFullYear()} Waboba. All Rights Reserved.
          </p>
          <p>
            <Button
              className="text-white text-xs underline"
              title="Terms of Use and Privacy Policy"
              link="/terms-privacy"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPages;
