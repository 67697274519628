import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Spinner from "../components/shared/Spinner";
import Errors from "../components/shared/Errors";

const FETCH_FEATURED_PRODUCTS = gql`
   query FEATURED_PRODUCTS($slugs: [String!]) {
      products: featureProductLines(slugs: $slugs) {
         id
         name
         mainImage(size: "300x300")
         slug
      }
   }
`;

const ProductLink = ({ image, name, url }) => (
   <Link
      to={url}
      className="flex flex-col justify-center mb-4 text-blue"
      style={{ flexBasis: 200 }}
   >
      <div
         className="bg-contain bg-center bg-no-repeat"
         style={{
            backgroundImage: `url(${image})`,
            width: 200,
            height: 200,
            maxWidth: "100%"
         }}
      />
      <span className="text-base text-center my-2">{name}</span>
   </Link>
);

const products = [
   [
      { slug: "original-bold", name: "Waboba Original" },
      { slug: "pro", name: "Waboba Pro" },
      { slug: "waboba-catch", name: "Waboba Catch" },
      { slug: "dive-tails", name: "Dive Tails" },
      { slug: "deluxe-beach-ball", name: "Deluxe Beach Ball" }
   ],
   [
      { slug: "mini-water-cracket", name: "Mini Water Cracket" },
      { slug: "water-cracket", name: "Water Cracket" },
      { slug: "mini-moon-cracket", name: "Mini Moon Cracket" },
      { slug: "moon-cracket", name: "Moon Cracket" }
   ],
   [
      { slug: "tailball", name: "Tailball" },
      { slug: "bounceback", name: "Bounceback" },
      { slug: "cornhole", name: "Cornhole" }
   ],
   [
      { slug: "mini-moon-lacrosse", name: "Mini Moon Lacrosse" },
      { slug: "pickleball-set", name: "Pickleball Set" },
      { slug: "bigring", name: "Bigring" },
      { slug: "tailwind", name: "Tailwind" }
   ]
];
// the name is used to display the custom product name in the UI

const socialImages = [
   "https://cdn.waboba.com/public/biz/landing2025/2025-1.jpeg",
   "https://cdn.waboba.com/public/biz/landing2025/2025-2.jpeg",
   "https://cdn.waboba.com/public/biz/landing2025/2025-3.jpeg",
   "https://cdn.waboba.com/public/biz/landing2025/2025-4.jpg",
   "https://cdn.waboba.com/public/biz/landing2025/2025-5.jpeg",
   "https://cdn.waboba.com/public/biz/landing2025/2025-6.jpg"
];

const Landing2025 = () => {
   return (
      <div>
         <div className="w-full relative overflow-hidden bg-cover bg-center max-h-screen aspect-video">
            <video
               autoPlay
               loop
               muted
               playsInline
               width="1280"
               height="720"
               preload="auto"
               className="absolute"
               style={{
                  minWidth: "100%",
                  minHeight: "100%",
                  width: "auto",
                  height: "auto",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
               }}
            >
               <source
                  src="https://cdn.waboba.com/public/biz/landing2025/bounceback.mp4"
                  type="video/mp4"
               />
            </video>
         </div>

         <div className="mb-8 p-4 bg-white">
            <h2 className="text-4xl text-center my-16">
               NEW PRODUCTS FOR 2025
            </h2>
            <FeatureProducts groups={products} />
         </div>

         <SocialImages images={socialImages} />
      </div>
   );
};

const SocialImages = ({ images }) => (
   <div className="flex flex-wrap px-2">
      {images.map((i, index) => (
         <div
            key={index}
            className="p-2 relative w-full md:w-1/3 h-20"
            style={{ height: "26vw" }}
         >
            <div
               className="relative bg-cover bg-no-repeat bg-center w-full h-full"
               style={{
                  backgroundImage: `url(${i})`
               }}
            />
         </div>
      ))}
   </div>
);

const FeatureProducts = ({ groups }) => {
   const slugs = groups.flatMap(group => group.map(product => product.slug));
   const { loading, error, data } = useQuery(FETCH_FEATURED_PRODUCTS, {
      variables: { slugs }
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="mx-auto flex flex-col p-4 flex-wrap items-center justify-center">
         {groups.map((group, groupIndex) => (
            <div
               key={groupIndex}
               className="mt-4 flex flex-wrap justify-center"
            >
               {group.map((info, index) => {
                  const product = data.products.find(i => i.slug === info.slug);
                  if (!product) return <div>{info.name}</div>;
                  return (
                     <ProductLink
                        key={index}
                        image={product.mainImage}
                        // name={product.name}
                        name={info.name}
                        url={`/products/${product.slug}`}
                     />
                  );
               })}
            </div>
         ))}
      </div>
   );
};

export default Landing2025;
